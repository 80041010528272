export class MissionLogs {
  static setup() {
    $('#mission_log_mission_id').change(function() {
      let points = $(this).find('option:selected').data('points');
      $('#mission_log_points').val(points);
    });

    $('#mission_log_reward_id').change(function() {
      let points = $(this).find('option:selected').data('points');
      $('#mission_log_points').val(points * -1);
    });

    this.populateRewardPoints();
  }

  static populateRewardPoints() {
    if($('#mission_log_reward_id').length) {
      let points = $('#mission_log_reward_id').find('option:selected').data('points');
      $('#mission_log_points').val(points * -1);
    }
  }
}

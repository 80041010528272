export class AutoPrint {
	static setup() {
		let autoPrint = new URL(window.location.href).searchParams.get('auto_print');

		if(autoPrint == 'true') {
			const urlWithoutQueryString = window.location.origin + window.location.pathname;
			const pdfWindow = window.open(urlWithoutQueryString + '.pdf', '_blank');
			// Check if the new window was opened successfully
			if (pdfWindow) {
			  // Wait for the PDF to load, then trigger the print dialog
			  pdfWindow.onload = function() {
			    pdfWindow.print();
			  };
			} else {
			  // Browser may have blocked the new window
			  console.log('Opening PDF blocked by the browser');
			}
		} else {
			// alert('NOT AUTO PRINTING');
		}
	}
}
import { Items } from '../src/items';
import '../src/css/mission.scss';
import '../src/css/rewards.scss';

require('chartkick');
require('chart.js');
require('@nathanvda/cocoon');

import '../src/main.scss';
import { MissionLogs } from '../src/mission_logs';
import { AutoPrint } from '../src/autoPrint';

$(document).ready(function() {
  Items.setup();
  MissionLogs.setup();
  AutoPrint.setup();
});

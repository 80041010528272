export class Items {
  static setup() {
    $(document).on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
      let items = Items.itemFields(insertedItem);
      Items.toggleMilkContainersTable(insertedItem, false);
      $(insertedItem).find('.selectpicker').selectpicker();
      if(items.length) {
        items.change(function() {
          let show = $(this).find('option:selected').data('milk');
          let unitPrice = $(this).find('option:selected').data('unit-price');
          let unitType = $(this).find('option:selected').data('unit-type');
          Items.toggleMilkContainersTable(insertedItem, show);
          Items.toggleQuantityField(insertedItem, !show, unitType);
          Items.updateUnitPrice(insertedItem, unitPrice) ;
        });
      }
    });
  }

  static itemFields(element) {
    return $(element).find('select[name^=\'order[order_items_attributes]\']').filter(function(index, input) {
      return input.name.includes('[item_id]');
    });
  }
  
  static toggleMilkContainersTable(parent, show) {
    let table = $(parent).find('.order_order_items_milk_container_ids');
      
    if(show) {
      table.show();
    } else {
      table.hide();
    }
  }

  static toggleQuantityField(parent, show, unitType) {
    let field = $(parent).find('.order_order_items_quantity');

    if(show) {
      field.find('label').html(unitType);
      field.show();
    } else {
      field.hide();
    }
  }

  static updateUnitPrice(parent, val) {
    let input = $(parent).find('.order_order_items_unit_price input');
    input.val(val);
  }
}
